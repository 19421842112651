export const ExpirationRiskColorMap: Record<string, number> = {
  Expired: 30,
  Return: 31,
  Returned: 38, //TODO: Use another name for return savings. Using this temporarily for demo.
  Restock: 32,
  Restocked: 32,
  Recall: 33,
  Recalled: 33,
  Relocate: 34,
  Relocated: 39,
  'In Stock At Risk': 35,
  'In Stock': 36,
  'No Stock': 37,

  Southwest: 0,
  Southeast: 1,
  West: 2,
  East: 3,
  Mountain: 4,
  South: 5
};

export const SavingsColorMap2: Record<string, string> = {
  Relocate: '#F9D300',
  Relocated: '#F9D300',
  Return: '#BA8146',
  Returned: '#BA8146'
};

export const GetChartColorClass = (map: Record<string, string>, name: string): string | undefined => {
  if (Object.hasOwn(map, name)) {
    return map[name];
  }
  return undefined;
};

export const GetChartColorIndex = (map: Record<string, number>, name: string): number | undefined => {
  if (Object.hasOwn(map, name)) {
    return map[name];
  }
  return undefined;
};

